<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitDeleteMultipleRecords="deleteDonation($event, null , true)"
    />

    <vs-popup
      title="تفاصيل التبرع"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 px-4 mt-2">
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.name"
          />
        </div>

        <div>
          <label>المبلغ :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.amount"
          />
        </div>

        <div>
          <label>الهاتف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.phone"
          />
        </div>

        <div>
          <label>الهاتف المحمول :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.mobile"
          />
        </div>

        <div>
          <label>المنطقة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.district"
          />
        </div>

        <div>
          <label>العنوان :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="donation.address"
          />
        </div>

        <div
          v-if="donation.note"
          class="col-span-2"
        >
          <label>الملاحظات :</label>
          <vs-textarea
            placeholder=""
            v-model="donation.note"
            disabled
            class="w-full mt-3"
            color="#5E445A"
          />
        </div>
      </div>

      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import AgTable from "../../../shared/shared-components/ag-grid/AgTable.vue";
import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell.vue";
import {RepositoryFactory} from "../../donate-repositories/donateRepositoryFactory";
import "quill/dist/quill.snow.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const donationRepo = RepositoryFactory.get('donation');
export default {
  name: "DonationMain",
  components: {
    AgTable
  },

  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActiveDetails: false,
      dialogTitle: String,
      donation: {
        address: null,
        amount: null,
        id: null,
        lat: null,
        lon: null,
        mobile: null,
        name: null,
        note: null,
        phone: null,
      },
      map: null,
      lngLat: [36.292, 33.513],
      geoCoder: null,
      marker: null,
      region: null,

    };

  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الهاتف',
          field: 'phone',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الموبايل',
          field: 'mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'العنوان',
          field: 'address',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الكمية',
          field: 'amount',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'برنامج التبرع',
          field: 'details.donation_program',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'اسم الجابي',
          field: 'details.aljabi.name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'رقم الجابي',
          field: 'details.aljabi.mobile',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المنطقة',
          field: 'district',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'التاريخ',
          field: 'created_at',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الملاحظات',
          field: 'note',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteDonation(id, rowIndex);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ["delete", "view"],
          },
        },
      ];
    },

    deleteDonation(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      donationRepo.delete(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllDonationRequests();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    viewRecordDetails(record) {
      this.lngLat = [record.lon, record.lat];
      this.donation = record;
      this.popupActiveDetails = true;
      /* setTimeout(() => {
           this.initMap();
       }, 200);*/
    },
    /*  initMap() {
          if (this.map === null) {
              mapboxgl.accessToken =
                  "pk.eyJ1Ijoid2FzZWVtLTQ0MyIsImEiOiJja2gyOWM5azkwY2p5MzFsczh2ZXg5MWU1In0.DaMw9JBJWlux5K7ZffTyYA";
              let map = new mapboxgl.Map({
                  container: "map",
                  style: "mapbox://styles/waseem-443/ckh2e3q61040h19pjd3j3qo48",
                  center: this.lngLat,
                  zoom: 12,
              });
              let marker = new mapboxgl.Marker({draggable: false}).setLngLat(this.lngLat).addTo(map);
              this.map = map;
              this.marker = marker;

          } else {
              this.marker.setLngLat(this.lngLat)
              this.map.setCenter(this.lngLat)
          }
      },*/
    fetchAllDonationRequests() {
      donationRepo.fetchAllDonationRequests().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
    /* convertLngLatToString(lon,lat){
        donationRepo.convertLngLatToString(lon,lat).then((data) => {
         this.region = data;
            this.isLoading = false;
        });
        return this.region ;

    },*/
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllDonationRequests();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>


<style>

#map {
  height: 220px;
}

.vs-popup--title {
  background-color: #5E445A !important;
  text-align: center !important;
}

.vs-popup--title h3 {
  color: white !important;
  padding: 12px 20px !important;
}

.vs-textarea {
  font-family: 'Cairo', sans-serif !important;
}

.sectionName {
  font-weight: 700;
  font-size: 17px;
  color: #5E445A;
}


</style>
